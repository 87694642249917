import { render, staticRenderFns } from "./RouterTabs.vue?vue&type=template&id=f8880236&scoped=true&"
import script from "./RouterTabs.vue?vue&type=script&lang=js&"
export * from "./RouterTabs.vue?vue&type=script&lang=js&"
import style0 from "./RouterTabs.vue?vue&type=style&index=0&id=f8880236&lang=scss&scoped=true&"
import style1 from "./RouterTabs.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8880236",
  null
  
)

export default component.exports