<!--
 * @Author: mulingyuer
 * @Date: 2021-04-20 16:32:23
 * @LastEditTime: 2021-04-22 18:04:02
 * @LastEditors: mulingyuer
 * @Description: 路由tab组件
 * @FilePath: \super-admin-vue\src\base\components\Default\RouterTabs.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-tabs
    v-if="visible"
    class="router-tab"
    v-model="activeName"
    @tab-click="handleClick"
    :before-leave="onBeforeLeave"
  >
    <el-tab-pane
      v-for="tab in ordinaryTabArr"
      :key="tab.name"
      :label="tab.label"
      :name="tab.name"
    >
    </el-tab-pane>
    <!-- 更多 -->
    <el-tab-pane v-if="tabArr.length > max" name="more">
      <template slot="label">
        <el-dropdown
          class="more-dropdown"
          :class="{ active: activeMore || moreDropdownActive }"
          trigger="click"
          @visible-change="onVisibleChange"
          @command="onDropdownItemClick"
        >
          <div class="dropdown-toggle">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="more-dropdown-menu">
            <el-dropdown-item
              v-for="item in moreTabArr"
              :key="item.name"
              :command="item.name"
              :class="{ active: activeName === item.name }"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { getType } from "@/base/utils/tool";
export default {
  model: {
    prop: "name",
    event: "handlerName",
  },
  props: {
    //选中的路由name
    name: {
      type: String,
      required: true,
    },
    //tab数据
    tabArr: {
      type: Array,
      required: true,
    },
    //query参数
    queryData: {
      type: Object,
    },
    //params参数
    paramsData: {
      type: Object,
    },
    //限制多少个才显示更多
    max: {
      type: Number,
      default: 9999,
    },
    //是否自动隐藏
    autoHide: {
      type: Boolean,
      default: true,
    },
    //特殊处理params 函数
    // @params:    params: 路由参数 toRouteName: 路由名
    // @return    params:  处理好的 params
    handlerParams: {
      type: Function,
    },
  },
  data() {
    return {
      activeName: "", //选中的tab name
      activeMore: false, //是否选中more
    };
  },
  computed: {
    //当前路由name
    routerName() {
      return this.$route.name;
    },
    //当前选中tab数据-一定会有值的
    activeTab() {
      return this.tabArr.find((item) => item.name === this.activeName);
    },
    //当前路由对应的tab数据-不一定会有值
    routeTab() {
      return this.tabArr.find((item) => item.name === this.routerName);
    },
    //限制内的数据
    ordinaryTabArr() {
      let arr = [];
      if (this.tabArr) {
        arr = this.tabArr.slice(0, this.max);
        arr = arr.filter((item) => {
          if (item.hidden !== undefined && typeof item.hidden !== 'boolean') {
            if (!item.hidden()) {
              return item;
            }
          }
          if (!item.hidden) {
            return item;
          }
        });
      }
      console.log(arr)
      return arr;
    },
    //更多的数据
    moreTabArr() {
      let arr = [];
      if (this.tabArr.length > this.max) {
        arr = this.tabArr.slice(this.max);
      }
      return arr;
    },
    //更多菜单中是否有选中项
    moreDropdownActive() {
      let flag = false;
      const tab = this.moreTabArr.find((item) => item.name === this.activeName);
      if (tab) flag = true;
      return flag;
    },
    //是否显示
    visible() {
      let flag = false;
      if (this.autoHide && this.routeTab) {
        flag = true;
      } else if (!this.autoHide) {
        flag = true;
      }
      return flag;
    },
  },
  methods: {
    //tab切换事件
    async handleClick() {
      if (this.activeName !== this.routerName) {
        //选中的tab数据
        let query = {};
        let params = {};
        if (this.activeTab) {
          if (this.activeTab.query && getType(this.queryData) === "object") {
            query = { ...this.queryData };
          }
          if (this.activeTab.params && getType(this.paramsData) === "object") {
            params = { ...this.paramsData };
          }
        }
        // 执行特殊处理参数函数
        if (this.$props.handlerParams) {
          params = await this.$props.handlerParams(params, this.activeName);
        }
        console.log(params, this.activeName);
        this.$router.push({ name: this.activeName, query, params });
        this.$emit("handlerName", this.activeName);
      }
    },
    //首次初始化
    init() {
      this.activeName = this.name;
      //如果当前路由name是tabArr中的数据，且activeName与路由name不同
      if (this.routeTab && this.activeName !== this.routerName) {
        this.activeName = this.routeTab.name;
        this.$emit("handlerName", this.activeName);
      }
    },
    //tab切换之前-more不允许切换
    onBeforeLeave(activeName) {
      if (activeName === "more") return false;
      return true;
    },
    //more下拉菜单事件
    onVisibleChange(flag) {
      this.activeMore = flag;
    },
    //下拉菜单点击事件
    onDropdownItemClick(name) {
      this.activeName = name;
      this.handleClick();
    },
    //其他组件触发路由变化进行选中项调整
    outhChangeRouteName(name) {
      if (this.routeTab && this.activeName !== name) {
        this.activeName = name;
        this.$emit("handlerName", this.activeName);
      }
    },
  },
  created() {
    this.init();
  },
  watch: {
    routerName(val) {
      this.outhChangeRouteName(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.router-tab {
  margin-bottom: 24px;
  .more-dropdown {
    &.active {
      .dropdown-toggle {
        color: #3576ff;
      }
    }
    .dropdown-toggle {
      color: #303133;
      outline: none;
      cursor: pointer;
      &:hover {
        color: #3576ff;
      }
    }
  }
}
</style>
<style lang="scss">
.more-dropdown-menu {
  .el-dropdown-menu__item {
    &.active {
      color: #3576ff;
    }
  }
}
</style>
