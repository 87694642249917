<template>
  <div class="content">
    <div v-if="ShowRouterTabs">
      <RouterTabs v-model="activeName" :tabArr="tabs" :queryData="query" :paramsData="params" :max="7" />
    </div>
    <router-view />
  </div>
</template>

<script>
import RouterTabs from "@/base/components/Default/RouterTabs.vue";
export default {
  data() {
    return {
      ShowRouterTabs: true, // 控制tab栏显示隐藏
      listArr: ["editBrand", "checkBrand"], // 带tab栏的页面路由name
      activeName: "editBrand", //当前选中tab
      query: {}, //路由的query参数
      params: {}, // 路由的params参数
      // tab配置
      tabs: [
        { label: "内容信息", name: "editBrand", params: true },
        {
          label: "数据列表",
          name: "checkBrand",
          params: true,
        },
      ],
    };
  },
  created() {
    this.ShowRouterTabs = this.listArr.includes(this.$route.name)
      ? true
      : false;
    this.activeName = this.$route.name;
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    routeName(val) {
      this.ShowRouterTabs = this.listArr.includes(val) ? true : false;
      if (this.listArr.includes(val)) {
        this.params = this.$route.params;
        this.activeName = val;
      }
    },
  },
  components: { RouterTabs },
};
</script>

<style lang="scss" scoped>
.content {
}
</style>